import { useReducer } from "react";

export const STEP_MACHINE = {
  initial: {
    BID: "bid",
    BUYOUT: "buyout",
  },
  bid: {
    PREV: "initial",
    NEXT: "initial",
  },
  buyout: {
    PREV: "initial",
    NEXT: "delivery",
  },
  delivery: {
    NEXT: "confirmDelivery",
  },
  confirmDelivery: {
    PREV: "delivery",
    NEXT: "deliveryConfirmed",
  },
  deliveryConfirmed: {},
};

export type Step = keyof typeof STEP_MACHINE;
export type Event = {
  [S in keyof typeof STEP_MACHINE]: keyof (typeof STEP_MACHINE)[S];
}[keyof typeof STEP_MACHINE];

export type Send = React.Dispatch<Event>;

export const reducer = (state: Step, event: Event): Step => {
  const nextStep = (STEP_MACHINE[state] as Record<Event, Step>)[event];
  return nextStep;
};

export function useStepMachine() {
  return useReducer(reducer, "initial");
}
