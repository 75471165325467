import { twMerge } from "tailwind-merge";
import { XIcon } from "@/components/icons/x-icon";

export function CloseButton({
  className,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...rest}
      className={twMerge(
        "flex h-4 w-4 items-center justify-center rounded-full  border border-red-200 bg-red-200/40 fill-gray-50",
        className,
      )}
    >
      <XIcon className="h-2 w-2" />
    </button>
  );
}
