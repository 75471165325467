"use client";

import type { Step } from "../../step-machine";

export function DeliveryConfirmedStep({ step }: { step: Step }) {
  if (step !== "deliveryConfirmed") {
    return null;
  }
  return (
    <div>
      <p className="text-center font-sans text-sm">
        Thank you. The action is now closed.
      </p>
    </div>
  );
}
