import { CoinIcon } from "@/components/icons/coins-icon";
import { Section } from "../section";

export function Purchase(props: React.HTMLAttributes<HTMLDivElement>) {
  return <Section {...props} />;
}

Purchase.Header = Section.Header;
Purchase.Body = Body;
Purchase.Title = Title;
Purchase.Menu = Section.Menu;

function Title() {
  return <Section.Title>Purchase Service</Section.Title>;
}

function Body() {
  return (
    <Section.Body className="grid items-center justify-center">
      <h2 className="mb-2 text-center font-diablo text-xs uppercase">Buy</h2>
      <button
        type="button"
        className=" flex h-7 w-32 items-center justify-center font-diablo text-sm"
        style={{
          background: "transparent",
          borderImage: "url('/images/borders/border.png') 180 stretch",
          borderImageWidth: "auto",
          borderImageSlice: "0 40 0 90",
        }}
      >
        <CoinIcon className="w- mr-1 h-3 text-yellow-100" />
        200.000.000
      </button>
    </Section.Body>
  );
}
