"use client";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";
import { CoinIcon } from "@/components/icons/coins-icon";
import { Section } from "../section";

import type { User } from "@/types";

const authenticatedUser: User = {
  username: "generalmort",
  fullName: "General Mort",
};

export function Offers(props: React.HTMLAttributes<HTMLDivElement>) {
  return <Section {...props} />;
}

Offers.Header = Section.Header;
Offers.Body = Section.Body;
Offers.Title = Title;
Offers.Menu = Section.Menu;
Offers.Offer = Offer;

function Title() {
  return <Section.Title>Offers</Section.Title>;
}

function Offer({
  datetime,
  user,
  amount,
}: {
  datetime: string;
  user: User;
  amount: number;
}) {
  return (
    <div className="  border-b border-gray-700 p-2 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-gray-700">
      <div className="mb-1 font-diablo text-xs uppercase text-gray-100">
        <span>{format(datetime, "h:mm aaa")}</span>
      </div>

      <div className="grid grid-cols-[1fr_auto]">
        <div>
          <div className="text-sm">
            <span
              className={twMerge(
                authenticatedUser.username === user.username &&
                  "font-semibold text-yellow-100",
              )}
            >
              {user.fullName}
            </span>
            <span> submitted an offer</span>
          </div>
        </div>
        <div className="flex items-center font-diablo text-sm">
          <CoinIcon className="mr-1 h-3 w-3 text-yellow-100" />
          {Intl.NumberFormat("en", { notation: "compact" }).format(amount)}
        </div>
      </div>
    </div>
  );
}
