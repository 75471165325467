import { format } from "date-fns";

import { CoinIcon } from "@/components/icons/coins-icon";
import { Section } from "../section";

export function ServiceDetails(props: React.HTMLAttributes<HTMLDivElement>) {
  return <Section {...props} />;
}

ServiceDetails.Header = Section.Header;
ServiceDetails.Body = Section.Body;
ServiceDetails.Title = Title;
ServiceDetails.Menu = Section.Menu;
ServiceDetails.Price = Price;
ServiceDetails.TimeRemaning = TimeRemaning;

function Title() {
  return <Section.Title>Service Details</Section.Title>;
}

function Price({ amount }: { amount: number }) {
  return (
    <Row
      title="Price"
      value={
        <Coin>
          {Intl.NumberFormat("en", { notation: "compact" }).format(amount)}
        </Coin>
      }
    />
  );
}

function TimeRemaning({ datetime }: { datetime: string }) {
  return (
    <Row
      title="Time Remaining"
      value={`${format(datetime, "H'h' m'min'")} Left`}
    />
  );
}

function Coin({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="flex items-center">
      <CoinIcon className="mr-1 h-3 w-3 text-yellow-100" />
      {children}
    </div>
  );
}
function Row({
  title,
  value,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
}) {
  return (
    <div className="flex items-center justify-between p-2  [&:not(:last-child)]:border-b [&:not(:last-child)]:border-gray-700">
      <div className="font-diablo text-sm">{title}</div>
      <div className="font-diablo text-sm">{value}</div>
    </div>
  );
}
