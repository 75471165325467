"use client";

import React from "react";
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownPortal,
} from "@/components/dropdown-menu";
import { DropdownTriggerProfile } from "./components/dropdown-trigger-profile";
import { DropdownUserSection } from "./components/dropdown-user-profile";
import { DropdownMenuProfileDivider } from "./components/dropdown-divider-profile";
import { DropdownMenuProfileFooter } from "./components/dropdown-footer-profile";

DropdownMenuProfile.Trigger = DropdownTriggerProfile;
DropdownMenuProfile.Portal = DropdownProfilePortal;
DropdownMenuProfile.Content = DropdownContentProfile;
DropdownMenuProfile.Item = DropdownItemProfile;
DropdownMenuProfile.UserSection = DropdownUserSection;
DropdownMenuProfile.Divider = DropdownMenuProfileDivider;
DropdownMenuProfile.Footer = DropdownMenuProfileFooter;

export function DropdownMenuProfile({
  children,
}: React.PropsWithChildren): JSX.Element {
  return <Dropdown>{children}</Dropdown>;
}

function DropdownProfilePortal({
  children,
}: React.PropsWithChildren): JSX.Element {
  return <DropdownPortal>{children}</DropdownPortal>;
}

function DropdownContentProfile({
  children,
}: React.PropsWithChildren): JSX.Element {
  return (
    <DropdownContent className="DropdownMenuContent w-64 rounded-md bg-gray-900 p-3">
      {children}
    </DropdownContent>
  );
}

function DropdownItemProfile({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}): JSX.Element {
  return (
    <DropdownItem
      asChild
      className="cursor-pointer p-1 py-2 outline-none data-[highlighted]:bg-gray-800"
      onSelect={(event) => {
        event.preventDefault();
        onClick();
      }}
    >
      {children}
    </DropdownItem>
  );
}
