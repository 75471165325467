import { twMerge } from "tailwind-merge";
import { ChevronDown, ArrowDownNarrowWide } from "lucide-react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectIcon,
  SelectTrigger,
  SelectValue,
} from "@/components/select";

export function ActivityFilters() {
  return (
    <div className={twMerge("mb-4 grid grid-cols-2 gap-2 px-2")}>
      <Select>
        <SelectTrigger>
          <SelectValue className="bg-primary" placeholder="Select" />
          <SelectIcon asChild>
            <ChevronDown className="h-5 w-5 opacity-80" />
          </SelectIcon>
        </SelectTrigger>
        <SelectContent className="text-xs">
          <SelectGroup>
            {Array.from({ length: 4 }).map((_, i) => {
              return (
                <SelectItem
                  className="font-diablo text-base"
                  key={i}
                  value={String(i)}
                >
                  Option {i}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select>
        <SelectTrigger>
          <SelectValue placeholder="Select" />
          <SelectIcon asChild>
            <ArrowDownNarrowWide className="h-5 w-5 opacity-80" />
          </SelectIcon>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Array.from({ length: 4 }).map((_, i) => {
              return (
                <SelectItem
                  className="font-diablo text-base"
                  key={i}
                  value={String(i)}
                >
                  Option {i}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
