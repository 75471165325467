"use client";

import { twMerge } from "tailwind-merge";
import { AlignJustify } from "lucide-react";

export function Section({
  className,
  ...rest
}: React.HTMLAttributes<HTMLHeadElement>): JSX.Element {
  return (
    <div {...rest} className={twMerge("border border-gray-700", className)} />
  );
}

Section.Header = Header;
Section.Body = Body;
Section.Title = Title;
Section.Menu = Menu;

function Header({ children }: React.HTMLAttributes<HTMLHeadElement>) {
  return (
    <div className="relative flex h-6 items-center justify-center bg-gray-800">
      {children}
    </div>
  );
}

function Body({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return <div {...rest} className={twMerge("p-2", className)} />;
}

function Title({ children }: React.HTMLAttributes<HTMLHeadElement>) {
  return <h2 className="font-diablo text-xs uppercase">{children}</h2>;
}

function Menu() {
  return (
    <button type="button" className="absolute right-2 top-1">
      <AlignJustify className="h-4 w-4" />
    </button>
  );
}
