import { Section } from "../section";

export function ManageAuction(props: React.HTMLAttributes<HTMLDivElement>) {
  return <Section {...props} />;
}

ManageAuction.Header = Section.Header;
ManageAuction.Body = Body;
ManageAuction.Title = Title;
ManageAuction.Menu = Section.Menu;

function Title() {
  return <Section.Title>Manage Auction</Section.Title>;
}

function Body() {
  return (
    <Section.Body className="grid grid-cols-2">
      <div>
        <p className="mb-2 text-center font-diablo text-xs uppercase">
          Remove Auction
        </p>
        <button
          type="button"
          className=" flex h-7 w-32 items-center justify-center font-diablo text-xs uppercase"
          style={{
            background: "transparent",
            borderImage: "url('/images/borders/border.png') 180 stretch",
            borderImageWidth: "auto",
            borderImageSlice: "0 40 0 90",
          }}
        >
          Cancel
        </button>
      </div>
      <div>
        <p className="mb-2 text-center font-diablo text-xs uppercase">
          End auction
        </p>
        <button
          type="button"
          className=" flex h-7 w-32 items-center justify-center font-diablo text-xs uppercase"
          style={{
            background: "transparent",
            borderImage: "url('/images/borders/border.png') 180 stretch",
            borderImageWidth: "auto",
            borderImageSlice: "0 40 0 90",
          }}
        >
          Accept Offer
        </button>
      </div>
    </Section.Body>
  );
}
