"use client";

import { ScrollArea } from "@/components/scroll-area";
import { CloseButton } from "@/components/close-button";
import { Purchase } from "./components/purchase-section";
import { ManageAuction } from "./components/manage-auction-section";
import { AuctionDetails } from "./components/auction-details-section";
import { Offers } from "./components/offers-section";
import { ServiceDetails } from "./components/service-details-section";
import { Transactions } from "./components/transactions-section";
import { MakeAnOffer } from "./components/make-an-offer-section";

export function AuctionSidebar({
  children,
}: React.PropsWithChildren): JSX.Element {
  return <div className="flex flex-col rounded-md bg-gray-900">{children}</div>;
}

AuctionSidebar.Header = Header;
AuctionSidebar.Body = Body;
AuctionSidebar.Title = Title;
AuctionSidebar.CloseBtn = CloseBtn;
AuctionSidebar.Purchase = Purchase;
AuctionSidebar.ManageAuction = ManageAuction;
AuctionSidebar.AuctionDetails = AuctionDetails;
AuctionSidebar.Offers = Offers;
AuctionSidebar.ServiceDetails = ServiceDetails;
AuctionSidebar.Transactions = Transactions;
AuctionSidebar.MakeAnOffer = MakeAnOffer;

function Header({ children }: React.PropsWithChildren) {
  return (
    <div className="mb-2 flex h-10 items-center justify-between px-2">
      {children}
    </div>
  );
}

function Body({ children }: React.PropsWithChildren) {
  return (
    <div className="relative flex-1">
      <div className="absolute inset-0 flex h-full min-w-full">
        <ScrollArea className="top-0 max-w-full flex-1 overflow-hidden">
          <div className="items-center justify-center ">
            <div className="grid gap-2 px-2">{children}</div>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}

function Title() {
  return (
    <h3 className="flex items-center justify-between font-diablo  uppercase">
      Auction Information
    </h3>
  );
}

function CloseBtn(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return <CloseButton {...props} />;
}
