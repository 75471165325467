"use client";

import { CloseButton } from "@/components/close-button";
import { MoneyBagIcon } from "@/components/icons/money-bag";
import { ActivityFilters } from "./components/activity-filters";

export function ActivitySidebar({
  children,
}: React.PropsWithChildren): JSX.Element {
  return (
    <aside className="flex w-80 flex-col overflow-hidden rounded-md bg-gray-800 transition-all duration-200">
      {children}
    </aside>
  );
}

ActivitySidebar.Header = Header;
ActivitySidebar.Body = Body;
ActivitySidebar.Title = Title;
ActivitySidebar.CloseBtn = CloseBtn;

ActivitySidebar.ActivityFilters = ActivityFilters;

function Header({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="mb-2 flex h-10 items-center justify-between px-2">
      {children}
    </div>
  );
}

function Body({ children }: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return <div className="flex flex-1 flex-col">{children}</div>;
}

function Title(): JSX.Element {
  return (
    <h2 className="flex items-center uppercase">
      <div>
        <MoneyBagIcon className="mr-2 h-5 w-5" />
      </div>
      <div className="whitespace-nowrap font-diablo text-base">
        Trading activity
      </div>
    </h2>
  );
}

function CloseBtn(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
): JSX.Element {
  return <CloseButton {...props} />;
}
