import Img from "next/image";

export function DropdownMenuProfileDivider(): JSX.Element {
  return (
    <div className="my-1 flex items-center justify-center">
      <Img
        alt="Horizontal-bar"
        height="10"
        src="/assets/misc/horizontal-bar-header.svg"
        width="220"
      />
    </div>
  );
}
