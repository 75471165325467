"use client";

import { format } from "date-fns";
import { Section } from "../section";
import { CoinIcon } from "@/components/icons/coins-icon";

export function AuctionDetails(props: React.HTMLAttributes<HTMLDivElement>) {
  return <Section {...props} />;
}

AuctionDetails.Header = Section.Header;
AuctionDetails.Body = Section.Body;
AuctionDetails.Title = Title;
AuctionDetails.Menu = Section.Menu;

AuctionDetails.StartingBid = StartingBid;
AuctionDetails.BidIncrement = BidIncrement;
AuctionDetails.HighestBid = HighestBid;
AuctionDetails.ActiveBids = ActiveBids;
AuctionDetails.TimeRemaning = TimeRemaning;

function Title() {
  return <Section.Title>Auction Details</Section.Title>;
}

function StartingBid({ amount }: { amount: number }) {
  return (
    <Row
      title="Starting Bid"
      value={
        <Coin>
          {Intl.NumberFormat("en", { notation: "compact" }).format(amount)}
        </Coin>
      }
    />
  );
}
function BidIncrement({ amount }: { amount: number }) {
  return (
    <Row
      title="Starting Bid"
      value={
        <Coin>
          {Intl.NumberFormat("en", { notation: "compact" }).format(amount)}
        </Coin>
      }
    />
  );
}
function HighestBid({ amount }: { amount: number }) {
  return (
    <Row
      title="Starting Bid"
      value={
        <Coin>
          {Intl.NumberFormat("en", { notation: "compact" }).format(amount)}
        </Coin>
      }
    />
  );
}
function ActiveBids({ amount }: { amount: number }) {
  return <Row title="Starting Bid" value={`${amount} bids`} />;
}

function TimeRemaning({ datetime }: { datetime: string }) {
  return (
    <Row
      title="Starting Bid"
      value={`${format(datetime, "H'h' m'min'")} Left`}
    />
  );
}

function Row({
  title,
  value,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
}) {
  return (
    <div className="flex items-center justify-between p-2  [&:not(:last-child)]:border-b [&:not(:last-child)]:border-gray-700">
      <div className="font-diablo text-sm">{title}</div>
      <div className="font-diablo text-sm">{value}</div>
    </div>
  );
}

function Coin({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="flex items-center">
      <CoinIcon className="mr-1 h-3 w-3 text-yellow-100" />
      {children}
    </div>
  );
}
