import { DropdownMenuProfile } from "../../index";
import { DropdownActionArrow } from "./components/dropdown-actions-icons";

export function DropdownActionsProfile({
  icon,
  name,
  onClick,
}: {
  icon: JSX.Element;
  name: string;
  onClick: () => void;
}): JSX.Element {
  return (
    <DropdownMenuProfile.Item onClick={onClick}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <div className="LeftSlot">{icon}</div>

          <NameButton name={name} />
        </div>

        <div className="RightSlot">
          <DropdownActionArrow />
        </div>
      </div>
    </DropdownMenuProfile.Item>
  );
}

function NameButton({ name }: { name: string }): JSX.Element {
  return <span className="font-diablo text-sm uppercase">{name}</span>;
}
