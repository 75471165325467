"use client";

import { PlusIcon, MinusIcon } from "lucide-react";
import { CoinIcon } from "@/components/icons/coins-icon";

import type { Send, Step } from "../../step-machine";
import { useState } from "react";

export function IntialStep({
  step,
  children,
}: {
  step: Step;
  children?: React.ReactNode;
}) {
  if (step !== "initial") {
    return null;
  }
  return (
    <div className="grid grid-cols-2 items-center justify-items-center gap-2">
      {children}
    </div>
  );
}

IntialStep.Bid = Bid;
IntialStep.Buyout = Buyout;

function Bid({ send }: { send: Send }) {
  const [value, setValue] = useState<number>(200000000);
  return (
    <div className="flex items-end">
      <div className="flex h-7 items-center">
        <button
          type="button"
          className="mr-1 flex h-4 w-4 items-center justify-center rounded-sm bg-red-200"
          onClick={() => {
            setValue((prev) => {
              return Math.max(0, prev - prev * 0.5);
            });
          }}
        >
          <MinusIcon className="h-3 w-3" />
        </button>
      </div>
      <div>
        <h2 className="mb-1 text-center font-diablo text-xs uppercase">
          Enter BID
        </h2>
        <button
          type="button"
          className=" flex h-7 w-28 items-center justify-center font-diablo text-xs"
          onClick={() => {
            send("BID");
          }}
          style={{
            borderImage: "url('/assets/buttons/button-blue.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
        >
          <CoinIcon className="w- mr-1 h-3 text-yellow-100" />
          {Intl.NumberFormat("en", {}).format(value)}
        </button>
      </div>
      <div className="flex h-7 items-center">
        <button
          type="button"
          onClick={() => {
            setValue((prev) => {
              return Math.max(0, prev + prev * 0.5);
            });
          }}
          className="ml-1 flex h-4 w-4 items-center justify-center rounded-sm bg-green-200"
        >
          <PlusIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
}

function Buyout({ value, send }: { value: number; send: Send }) {
  return (
    <div>
      <h2 className="mb-2 text-center font-diablo text-xs uppercase">Buyout</h2>
      <div className="flex items-center">
        <button
          type="button"
          onClick={() => {
            send("BUYOUT");
          }}
          className=" flex h-7 w-28 items-center justify-center font-diablo text-xs"
          style={{
            borderImage: "url('/assets/buttons/button-green.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
        >
          <CoinIcon className="w- mr-1 h-3 text-yellow-100" />
          {Intl.NumberFormat("en", {}).format(value)}
        </button>
      </div>
    </div>
  );
}
