"use client";

import type { Send, Step } from "../../step-machine";

export function BuyoutStep({ send, step }: { send: Send; step: Step }) {
  if (step !== "buyout") {
    return null;
  }
  return (
    <div className="grid gap-2">
      <p className="text-center font-sans text-sm">
        <span>Please, confirm your buyout of </span>
        <span className="font-bold text-yellow-100">
          {Intl.NumberFormat("en", {}).format(200000000)}
        </span>{" "}
        gold
      </p>
      <div className="flex items-center justify-center gap-2">
        <button
          className="block h-[27px] w-[110px] font-diablo text-[10px] uppercase hover:scale-105  hover:shadow-md hover:shadow-red-100/10"
          style={{
            borderImage: "url('/assets/buttons/button-red.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
          onClick={() => {
            send("PREV");
          }}
        >
          Cancel
        </button>
        <button
          className="block h-[27px] w-[110px]  font-diablo text-[10px] uppercase hover:scale-105  hover:shadow-md hover:shadow-green-100/10"
          style={{
            borderImage: "url('/assets/buttons/button-green.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
          onClick={() => {
            send("NEXT");
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}
