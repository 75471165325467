"use client";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

const Dropdown = DropdownMenuPrimitive.Root;
const DropdownTrigger = DropdownMenuPrimitive.Trigger;
const DropdownPortal = DropdownMenuPrimitive.Portal;
const DropdownContent = DropdownMenuPrimitive.Content;
const DropdownItem = DropdownMenuPrimitive.Item;

export {
  Dropdown,
  DropdownTrigger,
  DropdownPortal,
  DropdownContent,
  DropdownItem,
};
