import { Avatar, AvatarFallback, AvatarImage } from "@/components/avatar";
import { DropdownTrigger } from "@/components/dropdown-menu";

export function DropdownTriggerProfile(): JSX.Element {
  return (
    <DropdownTrigger asChild className="outline-none">
      <Avatar asChild>
        <button
          className="flex h-10 w-10 items-center justify-center rounded-full bg-background-subtle"
          type="button"
        >
          <AvatarImage alt="@shadcn" src="https://github.com/shadcn.png" />
          <AvatarFallback>CN</AvatarFallback>
        </button>
      </Avatar>
    </DropdownTrigger>
  );
}
