"use client";

import { Section } from "../section";
import { IntialStep } from "./components/initial-step";
import { BidStep } from "./components/bid-step";
import { BuyoutStep } from "./components/buyout-step";
import { DeliveryStep } from "./components/delivery-step";
import { ConfirmDeliveryStep } from "./components/confirm-delivery-step";
import { DeliveryConfirmedStep } from "./components/delivery-confirmed-step";

import { useStepMachine, type Send, type Step } from "./step-machine";

export function MakeAnOffer({
  children,
}: Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  children: React.FC<{ step: Step; send: Send }>;
}) {
  const [step, send] = useStepMachine();
  return <Section>{children({ step, send })}</Section>;
}

MakeAnOffer.Header = Section.Header;
MakeAnOffer.Body = Section.Body;
MakeAnOffer.Title = Title;
MakeAnOffer.Menu = Section.Menu;
MakeAnOffer.IntialStep = IntialStep;
MakeAnOffer.BidStep = BidStep;
MakeAnOffer.BuyoutStep = BuyoutStep;
MakeAnOffer.DeliveryStep = DeliveryStep;
MakeAnOffer.ConfirmDeliveryStep = ConfirmDeliveryStep;
MakeAnOffer.DeliveryConfirmedStep = DeliveryConfirmedStep;

function Title({ step }: { step: Step }) {
  if (step === "bid" || step === "buyout") {
    return <Section.Title>Bid Confirmation</Section.Title>;
  }

  if (step === "delivery" || step === "confirmDelivery") {
    return <Section.Title>Auction Won</Section.Title>;
  }

  if (step === "deliveryConfirmed") {
    return <Section.Title>Auction Closed</Section.Title>;
  }

  return <Section.Title>Make an Offer</Section.Title>;
}
